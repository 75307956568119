
import { Navbar } from 'react-bootstrap';
import './footer.css';

export default function Header() {
    return (
        <Navbar className="Footer">
            <span className="Copyright">© Copyright Tyko Racing Team {(new Date().getFullYear())}</span>
        </Navbar>
    )
}

