import React from 'react';

export default function Summary(props) {
    const data = require('/public/summary/' + props.id + '.txt');
    const contentArray = data.split('#');
    let count = 1;
    return (
        <div id={props.id} className="PageSite">
            {contentArray.map(function (content) {
                return <p key={count++} className="Contenu">{content}</p>;
            })}
        </div>
    )
}
