import React, { useState, useEffect } from 'react';
import BackGround from '../images/Background.jpg';
import './App.css';
import { ImageBackground, StyleSheet, View, Dimensions, ScrollView } from 'react-native';
import Header from './header/header';
import Footer from './footer/footer';
import Accueil from './page/accueil';
import Galerie from './page/galerie';
import Course from './page/course';
import Contact from './page/contact';

const windowDimensions = Dimensions.get('window');
const screenDimensions = Dimensions.get('screen');

function App() {
  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
    screen: screenDimensions,
  });

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window, screen }) => {
        setDimensions({ window, screen });
      },
    );
    return () => subscription?.remove();
  });
  return (
    <View style={[styles.container, { width: dimensions.window.width, height: dimensions.window.height }]}>
      <Header />
      <ScrollView showsVerticalScrollIndicator={false} style={[styles.scrollview]}>
        <Accueil key="accueil" id="accueil"></Accueil>
        <Galerie key="galerie" id="galerie"></Galerie>
        <Course key="course" id="course"></Course>
        <Contact key="contact" id="contact"></Contact>
      </ScrollView>
      <ImageBackground source={BackGround} resizeMode="cover" style={[styles.fixed, styles.container, { zIndex: -1 }]}>
        <View style={styles.child} />
      </ImageBackground>
      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  fixed: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
    //flex: 1,
    //justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    lineHeight: 84,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000c0',
  },
  child: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.25)',
  },
});

export default App;
