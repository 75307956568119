import './page.css';
import React from 'react';
import Vignette from '../vignette/vignette';

const filename = 'galerie.txt';

export default function Galerie(props) {
    const data = require('/public/pagecontent/' + filename);
    const contentArray = data.split('#');
    return (
        <div key="D{props.id}" id={props.id} className="PageSite">
            <h1>Galerie</h1>
            <br></br>
            {contentArray.map(function (content) {
                return <div id={"DG" + content} key={"DG" + content}><p id={"P" + content} key={"P" + content} className="SubTitre">{content}</p>
                    <div id={"D2" + content} key={"D2" + content} className="Galerie"><Vignette id={content} key={"V" + content} /></div></div>;
            })}
        </div>
    )
}
