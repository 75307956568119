import './page.css';
import React from 'react';
import Summary from '../courseSummary/summary';

const filename = 'course.txt';

export default function Galerie(props) {
    const data = require('/public/pagecontent/' + filename);
    const contentArray = data.split('#');
    return (
        <div key="D{props.id}" id={props.id} className="PageSite">
            <h1>Course</h1>
            <br></br>
            {contentArray.map(function (content) {
                console.log(content);
                return <div id={"DG" + content} key={"DG" + content}><p id={"P" + content} key={"P" + content} className="SubTitre">{content}</p>
                    <div id={"D2" + content} key={"D2" + content} className="Contenu"><Summary id={content} key={"S" + content} /></div></div>;
            })}
        </div>
    )
}
