
import { Navbar } from 'react-bootstrap';
import './header.css';
import React, { useEffect, useState } from 'react';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faFileImage, faHome, faVcard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from "react-transition-group";

export default function Header() {
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isVerySmallScreen, setIsVerySmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 820px)");
        mediaQuery.addListener(handleSmall);
        handleSmall(mediaQuery);
        return () => {
            mediaQuery.removeListener(handleSmall);
        };
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 640px)");
        mediaQuery.addListener(handleVerySmall);
        handleVerySmall(mediaQuery);
        return () => {
            mediaQuery.removeListener(handleVerySmall);
        };
    }, []);

    const handleVerySmall = (mediaQuery) => {
        if (mediaQuery.matches) {
            setIsVerySmallScreen(true);
        } else {
            setIsVerySmallScreen(false);
        }
    };

    const handleSmall = (mediaQuery) => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    return (
        <Navbar className="Header">
            <img src={require("../../images/Emblème Tyko.png")} className="Logo" alt="logo" />
            <CSSTransition
                in={!isVerySmallScreen}
                timeout={350}
                classNames="NavAnimation"
                unmountOnExit
            >
                <img src={require("../../images/Logo Tyko 2.png")} className="Logo2" alt="logo2" />
            </CSSTransition>
            <CSSTransition
                in={!isSmallScreen}
                timeout={350}
                classNames="NavAnimation"
                unmountOnExit
            >
                <nav className="Nav NavGauche">
                    <a href="#accueil">Accueil</a>
                    <a href="#galerie">Galerie</a>
                    <a href="#course">Course</a>
                    <a href="#contact">Contact</a>
                </nav>
            </CSSTransition>
            <CSSTransition
                in={isSmallScreen}
                timeout={350}
                classNames="NavAnimation"
                unmountOnExit
            >
                <nav className="Nav NavGauche">
                    <a href="#accueil"><FontAwesomeIcon icon={faHome} /></a>
                    <a href="#galerie"><FontAwesomeIcon icon={faFileImage} /></a>
                    <a href="#contact"><FontAwesomeIcon icon={faVcard} /></a>
                </nav>
            </CSSTransition>
            <nav className="Nav NavDroit">
                <a href="https://www.instagram.com/tyko_racing_team/"><FontAwesomeIcon icon={faInstagram} /></a>
                <a href="mailto:tyko.contact@dahel.fr"><FontAwesomeIcon icon={faEnvelope} /></a>
            </nav>
            <button onClick={toggleNav} className="Expand">🍔</button>
        </Navbar>
    )
}

