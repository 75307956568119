import React from 'react';
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import './vignette.css';

const smallItemStyles = {
    cursor: 'pointer',
    objectFit: 'cover',
    width: '200px',
    height: '200px',
}

const options = {
    arrowPrev: true,
    arrowNext: true,
    zoom: false,
    close: true,
    counter: false,
    bgOpacity: 0.8,
    padding: { top: 10, bottom: 20, left: 50, right: 50 },
}

export default function Vignette(props) {
    const data = require('/public/images/' + props.id + '/content.txt');
    const imgArray = data.split("#");
    console.log(imgArray);
    return (<Gallery options={options}>
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 0fr)',
                gridGap: 10,
            }}
        >
            {imgArray.map(function (content) {
                const imgDetail = content.split("|");
                const source = process.env.PUBLIC_URL + "/images/" + props.id + "/" + imgDetail[0];
                const sourceSmall = process.env.PUBLIC_URL + "/images/" + props.id + "/small_" + imgDetail[0];
                return <Item cropped id={"IM" + content} key={"IM" + content} original={source} thumbnail={source} alt={content} width={imgDetail[1]} height={imgDetail[2]}>
                    {({ ref, open }) => (
                        < img
                            style={smallItemStyles}
                            src={sourceSmall}
                            onClick={open}
                            alt={content}
                            ref={ref}
                        />
                    )}
                </Item>;
            })}
        </div>
    </Gallery>
    )
}
