import './page.css';

export default function Contact(props) {
    return (
        <div id={props.id} className="PageSite">
            <h1>Contact</h1>
            <br></br>
        </div>
    )
}


