import Logo from '../../images/Emblème Tyko.png';
import './page.css';

const filename = 'accueil.txt';

export default function Accueil(props) {
    const data = require('/public/pagecontent/' + filename);
    const contentArray = data.split('#');
    let count = 1;
    return (
        <div id={props.id} className="PageSite">
            <h1>Accueil</h1>
            <br></br>
            <img src={Logo} className="App-logo" alt="logo" />
            {contentArray.map(function (content) {
                return <p key={count++} className="Contenu">{content}</p>;
            })}
        </div>
    )
}